import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Table, Card, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as XLSX from 'xlsx';
import './Page.css'
import { FaArrowUp } from 'react-icons/fa';
import logoSigma from '../assets/SigmaGroup.png';


function Page() {
  // Variabile de stare și hook-uri
  const [originalData, setOriginalData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [columnNames, setColumnNames] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [stockFilter, setStockFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('RON');
  const [selectedValue, setSelectedValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  const [selectedFilters, setSelectedFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState({});

  const filterFields = [
    { label: 'Distribuitor', value: 'Distribuitor', key: 'distributors' },
    { label: 'Brand', value: 'Brand', key: 'brands' },
    { label: 'Țară', value: 'Tara', key: 'countries' },
    { label: 'Familie Procesor', value: 'Familie procesor', key: 'processorFamilies' },
    { label: 'Model Procesor', value: 'Model Procesor', key: 'processorModels' },
    { label: 'Diagonala Display', value: 'Diagonala Display', key: 'displayDiagonals' },
    { label: 'Rezoluție Display', value: 'Rezolutie Display', key: 'displayResolutions' },
    { label: 'Format', value: 'Format', key: 'formats' },
    { label: 'Capacitate Memorie RAM', value: 'Capacitate Memorie RAM', key: 'ramCapacities' },
    { label: 'Capacitate SSD', value: 'Capacitate SSD', key: 'ssdCapacities' },
    { label: 'Tip placă video', value: 'Tip placa video', key: 'videoTypes' },
    { label: 'Model video', value: 'Model video', key: 'videoModels' },
    { label: 'Capacitate memorie video', value: 'Capacitate memorie video', key: 'videoMemoryCapacities' },
    { label: 'Rată Refresh', value: 'Rata Refresh', key: 'refreshRates' },
    { label: 'Sistem de operare', value: 'Sistem de operare', key: 'operatingSystems' },
    { label: 'Culoare', value: 'Culoare', key: 'colors' },
    { label: 'Layout Tastatură', value: 'Layout Tastatura', key: 'keyboardTypesLayouts' },
    { label: 'Tip laptop', value: 'Tip laptop', key: 'laptopTypes' },
    { label: 'Tastatură', value: 'Tastatura', key: 'keyboardTypes' },
    { label: 'Rezoluție.1', value: 'Rezolutie.1', key: 'displayResolutions1' },
    { label: 'Timp de răspuns', value: 'Timp de raspuns', key: 'responseTimes' },
    { label: 'Tip Boxe', value: 'Tip Boxe', key: 'speakerTypes' },
    { label: 'Producător placă video', value: 'Producator placa video', key: 'videoCardManufacturers' },
    { label: 'Model placă video', value: 'Model placa video', key: 'videoCardModels' },
    { label: 'Aspect Ratio', value: 'Aspect Ratio', key: 'aspectRatios' },
    { label: 'Boxe', value: 'Boxe', key: 'speakers' },
    { label: 'Reglaj pe înălțime', value: 'Reglaj pe inaltime', key: 'heightAdjustments' },
    { label: 'Pivot', value: 'Pivot', key: 'pivots' },
    { label: 'Display Interactiv', value: 'Display Interactiv', key: 'interactiveDisplays' },
    { label: 'Unghi de vizualizare', value: 'Unghi de vizualizare', key: 'viewingAngles' },
    { label: 'Difuzoare', value: 'Difuzoare', key: 'speakers1' },
    { label: 'Diagonala', value: 'Diagonala', key: 'diagonals' },
    { label: 'Rezoluție', value: 'Rezolutie', key: 'resolutions' },
    { label: 'Recomandat', value: 'Recomandat', key: 'recommended' },
    { label: 'Touchscreen', value: 'Touchscreen.1', key: 'touchscreen1' },
  ];

  // Funcții definite înainte de utilizare

  const containsAllSearchTerms = (item, searchQuery) => {
    const searchTerms = searchQuery.trim().split(/\s+/).map(term => term.toLowerCase());
    const description = item['Descriere'] ? item['Descriere'].toLowerCase() : '';
    const pn = item['PN'] ? item['PN'].toLowerCase() : '';

    return searchTerms.every(term => description.includes(term) || pn.includes(term));
  };

  const getUniqueValues = (data, key) => {
    return [...new Set(data.map(item => item[key]))]
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
  };

  const calculateCustomColumnValue = (item) => {
    return selectedValue ? item[selectedValue] : '';
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '.....';
    }
    return text;
  };

  const highlightSearchTerms = (description, pn) => {
    if (!searchQuery.trim()) {
      return { highlightedDescription: description, highlightedPN: pn };
    }

    const searchTerms = searchQuery.trim().split(/\s+/);
    let highlightedDescription = description;
    let highlightedPN = pn;

    searchTerms.forEach(term => {
      const regex = new RegExp(`(${term})`, 'gi');
      highlightedDescription = highlightedDescription.replace(regex, "<span style='background-color: yellow;'>$1</span>");
      highlightedPN = highlightedPN.replace(regex, "<span style='background-color: yellow;'>$1</span>");
    });

    return { highlightedDescription, highlightedPN };
  };

  const customColumnValues = useMemo(() => [
    'EUR EXW 0%', 'USD EXW 0%', 'EUR EXW 5%', 'USD EXW 5%',
    'EUR CIF 0%', 'USD CIF 0%', 'EUR CIF 5%', 'USD CIF 5%'
  ], []);

  // useEffect pentru a obține datele
  useEffect(() => {
    fetchData('https://api.sigmadistribution.ro/stoc_tot_notebook_atribute', 'stoc_tot_notebook_atribute');
  }, []);

  const fetchData = (url, categoryName) => {
    setIsLoading(true);
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setOriginalData(data);
        setSelectedCategory(categoryName);
        setColumnNames(getColumnNamesForCategory(categoryName));

        // Calculăm opțiunile de filtrare
        const newFilterOptions = {};
        filterFields.forEach(filterField => {
          const field = filterField.value;
          const key = filterField.key;
          newFilterOptions[key] = getUniqueValues(data, field);
        });
        setFilterOptions(newFilterOptions);

        // Inițializăm filtrele selectate
        const newSelectedFilters = {};
        filterFields.forEach(filterField => {
          const key = filterField.key;
          newSelectedFilters[key] = [];
        });
        setSelectedFilters(newSelectedFilters);

        setSelectedFilter('');
      })
      .catch(error => console.error('Fetch error:', error))
      .finally(() => setIsLoading(false));
  };

  const getColumnNamesForCategory = (categoryName) => {
    switch (categoryName) {
      case 'stoc_tot_aio_atribute':
      case 'stoc_tot_desktop_atribute':
      case 'stoc_tot_monitoare':
      case 'stoc_tot_notebook':
      case 'stoc_tot_notebook_atribute':
        return [
          'Distribuitor', 'Brand', 'PN', 'Titlu', 'Descriere', 'Stoc',
          'Pret achizitie RON', 'Pret achizitie EUR', 'Pret achizitie USD', 'Pret de vanzare'
        ];
      default:
        return [];
    }
  };

  const handleFetchData = (url, categoryName) => {
    fetchData(url, categoryName);
  };

  const exportToCsv = () => {
    if (!filteredData || filteredData.length === 0) {
      console.error('Nu există date disponibile pentru export.');
      return;
    }

    const headers = Object.keys(filteredData[0]);
    const data = [
      headers,
      ...filteredData.map(item =>
        headers.map(header => {
          return item[header] !== undefined ? item[header] : '';
        })
      )
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Produsele Dorite');
    XLSX.writeFile(wb, 'produsele_dorite.xlsx');
  };

  const exportToCsvSamurai = () => {
    try {
      const selectedSellingPriceColumn = selectedValue || 'Pret de vanzare';
  
      if (!selectedSellingPriceColumn) {
        console.error('Nu există o coloană de pret de vanzare selectată.');
        alert('Te rugăm să selectezi o coloană de pret de vanzare înainte de a exporta.');
        return;
      }
  
      const dataToExport = filteredData.filter(item => {
        const stoc = parseInt(item['Stoc'], 10);
        return !isNaN(stoc) && stoc > 0;
      });
  
      if (dataToExport.length === 0) {
        console.warn('Nu există produse cu stoc mai mare decât 0 pentru export.');
        alert('Nu există produse cu stoc mai mare decât 0 pentru export.');
        return;
      }
  
      const groupedData = dataToExport.reduce((acc, item) => {
        const pn = item['PN'];
        const brand = item['Brand'];
        const titlu = item['Titlu'];
        const currentStoc = parseInt(item['Stoc'], 10) || 0;
  
        const currentSellingPriceRaw = item[selectedSellingPriceColumn];
        const currentSellingPrice = parseFloat(currentSellingPriceRaw);
        const validSellingPrice = !isNaN(currentSellingPrice) ? currentSellingPrice : null;
  
        if (!acc[pn]) {
          acc[pn] = {
            Brand: brand,
            PN: pn,
            Titlu: titlu,
            'Suma Stoc': currentStoc,
            'Pret de vanzare': validSellingPrice
          };
        } else {
          acc[pn]['Suma Stoc'] += currentStoc;
          if (validSellingPrice !== null) {
            if (
              acc[pn]['Pret de vanzare'] === null ||
              validSellingPrice < acc[pn]['Pret de vanzare']
            ) {
              acc[pn]['Pret de vanzare'] = validSellingPrice;
            }
          }
        }
  
        return acc;
      }, {});
  
      // Excludem produsele cu Suma Stoc mai mică de 10
      const aggregatedData = Object.values(groupedData).filter(item => item['Suma Stoc'] >= 10);
  
      if (aggregatedData.length === 0) {
        console.warn('Nu există produse care să îndeplinească criteriile pentru export.');
        alert('Nu există produse care să îndeplinească criteriile pentru export.');
        return;
      }
  
      const headers = ['Brand', 'PN', 'Titlu', 'Suma Stoc', `Pret de vanzare (${selectedValue})`];
      const data = [
        headers,
        ...aggregatedData.map(item => {
          return headers.map(header => {
            if (header.startsWith('Pret de vanzare')) {
              return item['Pret de vanzare'] !== null ? item['Pret de vanzare'] : '';
            }
            return item[header] !== undefined ? item[header] : '';
          });
        })
      ];
  
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, 'Produsele Samurai');
      XLSX.writeFile(wb, 'produsele_samurai.xlsx');
      alert('Exportul a fost realizat cu succes!');
    } catch (error) {
      console.error('Eroare la exportul Samurai:', error);
      alert('A apărut o eroare la exportul datelor. Verifică consola pentru detalii.');
    }
  };
  

  const handleCheckboxChange = (filterKey, value, isChecked) => {
    setSelectedFilters(prevFilters => {
      const selectedValues = prevFilters[filterKey] || [];

      const updatedSelectedValues = isChecked
        ? [...selectedValues, value]
        : selectedValues.filter(v => v !== value);

      return { ...prevFilters, [filterKey]: updatedSelectedValues };
    });
  };

  const filteredData = useMemo(() => {
    const filteredResults = originalData.filter(item =>
      Object.keys(selectedFilters).every(key => {
        const selectedValues = selectedFilters[key];
        if (selectedValues.length === 0) return true;
  
        const field = filterFields.find(filter => filter.key === key)?.value;
        if (!field) return true;
  
        return selectedValues.includes(item[field]);
      }) &&
      (debouncedSearchQuery === '' || containsAllSearchTerms(item, debouncedSearchQuery)) &&
      (stockFilter !== 'in_stock' || parseInt(item['Stoc'], 10) > 0)
    );
  
    // Sortare date dacă sortConfig este setat
    if (sortConfig.key !== '') {
      return filteredResults.sort((a, b) => {
        let aValue, bValue;
  
        if (sortConfig.key === 'Pret de vanzare') {
          // Sortare numerică pentru 'Pret de vanzare'
          aValue = parseFloat(calculateCustomColumnValue(a)) || 0;
          bValue = parseFloat(calculateCustomColumnValue(b)) || 0;
        } else if (sortConfig.key === 'Stoc') {
          aValue = parseInt(a[sortConfig.key], 10) || 0;
          bValue = parseInt(b[sortConfig.key], 10) || 0;
        } else {
          aValue = a[sortConfig.key] || '';
          bValue = b[sortConfig.key] || '';
        }
  
        // Compararea în funcție de direcția sortării
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
  
    return filteredResults;
  }, [originalData, selectedFilters, debouncedSearchQuery, stockFilter, sortConfig]);
  

  const getFilteredOptionsForSelectedFilter = useMemo(() => {
    const optionsForFilters = {};

    filterFields.forEach(filterField => {
      const key = filterField.key;
      const field = filterField.value;

      const otherFilters = { ...selectedFilters };
      delete otherFilters[key];

      const filteredResults = originalData.filter(item =>
        Object.keys(otherFilters).every(otherKey => {
          const selectedValues = otherFilters[otherKey];
          if (selectedValues.length === 0) return true;

          const otherField = filterFields.find(filter => filter.key === otherKey)?.value;
          if (!otherField) return true;

          return selectedValues.includes(item[otherField]);
        }) &&
        (debouncedSearchQuery === '' || containsAllSearchTerms(item, debouncedSearchQuery)) &&
        (stockFilter !== 'in_stock' || parseInt(item['Stoc'], 10) > 0)
      );

      optionsForFilters[key] = getUniqueValues(filteredResults, field);
    });

    return optionsForFilters;
  }, [originalData, selectedFilters, debouncedSearchQuery, stockFilter]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const visibleColumns = useMemo(() => {
    return columnNames.filter(column => {
      if (selectedCurrency === 'RON') {
        return !['Pret achizitie EUR', 'Pret achizitie USD'].includes(column);
      } else if (selectedCurrency === 'EUR') {
        return !['Pret achizitie RON', 'Pret achizitie USD'].includes(column);
      } else if (selectedCurrency === 'USD') {
        return !['Pret achizitie RON', 'Pret achizitie EUR'].includes(column);
      }
      return true;
    });
  }, [columnNames, selectedCurrency]);

  const handleCustomColumnChange = (value) => {
    setSelectedValue(value);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleRowClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleStockFilterChange = (value) => {
    setStockFilter(value);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  return (
    <Container fluid className='pt-4'>
      <Row>
        <Col md={3}>

        <Card className='mb-3 shadow-sm text-center'>
    <Card.Body>
      <img
        src={logoSigma}
        alt="Logo Sigma Group"
        style={{ maxWidth: '150px', width: '100%', marginBottom: '20px' }}
      />
    </Card.Body>
  </Card>
  
          {/* Bara laterală stângă */}
          <Card className='mb-3 shadow-sm'>
            <Card.Body>
              <div className="d-grid gap-2">
                <Button
                  variant={selectedCategory === 'stoc_tot_notebook_atribute' ? 'danger' : 'primary'}
                  onClick={() => handleFetchData('https://api.sigmadistribution.ro/stoc_tot_notebook_atribute', 'stoc_tot_notebook_atribute')}
                  className='btn-lg mb-2'
                >
                  Încarcă Date Stoc Tot Notebook
                </Button>
                <Button
                  variant={selectedCategory === 'stoc_tot_aio_atribute' ? 'danger' : 'primary'}
                  onClick={() => handleFetchData('https://api.sigmadistribution.ro/stoc_tot_aio_atribute', 'stoc_tot_aio_atribute')}
                  className='btn-lg mb-2'
                >
                  Încarcă Date Stoc Tot AIO
                </Button>
                <Button
                  variant={selectedCategory === 'stoc_tot_desktop_atribute' ? 'danger' : 'primary'}
                  onClick={() => handleFetchData('https://api.sigmadistribution.ro/stoc_tot_desktop_atribute', 'stoc_tot_desktop_atribute')}
                  className='btn-lg mb-2'
                >
                  Încarcă Date Stoc Tot Desktop
                </Button>
                <Button
                  variant={selectedCategory === 'stoc_tot_monitoare' ? 'danger' : 'primary'}
                  onClick={() => handleFetchData('https://api.sigmadistribution.ro/stoc_tot_monitoare', 'stoc_tot_monitoare')}
                  className='btn-lg mb-2'
                >
                  Încarcă Date Stoc Tot Monitoare
                </Button>
                <Button
                  variant='success'
                  onClick={exportToCsv}
                  className='btn-lg mb-2'
                >
                  Exportă CSV
                </Button>
                <Button
                  variant='warning'
                  onClick={exportToCsvSamurai}
                  className='btn-lg mb-2'
                >
                  Exportă Samurai
                </Button>
                <Button
                  variant='info'
                  onClick={() => navigate('/crud')}
                  className='btn-lg'
                >
                  Accesați CRUD
                </Button>
                <Button
                  variant='info'
                  onClick={() => navigate('/romania_all')}
                  className='btn-lg mt-2'
                >
                  Romania_ALL
                </Button>
              </div>
            </Card.Body>
          </Card>

          {/* Selectarea monedei */}
          <Card className='mb-3 shadow-sm'>
            <Card.Body>
              <h5 className='text-center mb-3'>Selectează Moneda</h5>
              <Form>
                <Form.Check
                  type='radio'
                  id={`radio-ron`}
                  label={`RON`}
                  checked={selectedCurrency === 'RON'}
                  onChange={() => setSelectedCurrency('RON')}
                />
                <Form.Check
                  type='radio'
                  id={`radio-eur`}
                  label={`EUR`}
                  checked={selectedCurrency === 'EUR'}
                  onChange={() => setSelectedCurrency('EUR')}
                />
                <Form.Check
                  type='radio'
                  id={`radio-usd`}
                  label={`USD`}
                  checked={selectedCurrency === 'USD'}
                  onChange={() => setSelectedCurrency('USD')}
                />
              </Form>
            </Card.Body>
          </Card>

          {/* Selectarea prețului de vânzare */}
          <Card className='mb-3 shadow-sm'>
            <Card.Body>
              <h5 className='text-center mb-3'>Selectează Prețul de Vânzare</h5>
              <Form>
                {customColumnValues.length > 0 && customColumnValues.map((value, index) => (
                  <Form.Check
                    key={index}
                    type='radio'
                    id={`radio-custom-column-${index}`}
                    label={value}
                    checked={selectedValue === value}
                    onChange={() => handleCustomColumnChange(value)}
                  />
                ))}
              </Form>
            </Card.Body>
          </Card>

          {/* Filtrul de stoc */}
          <Card className='mb-3 shadow-sm'>
            <Card.Body>
              <h5 className='text-center mb-3'>Filtrează după Stoc</h5>
              <Form>
                <Form.Check
                  type='radio'
                  id={`radio-all`}
                  label={`Toate produsele`}
                  checked={stockFilter === 'all'}
                  onChange={() => handleStockFilterChange('all')}
                />
                <Form.Check
                  type='radio'
                  id={`radio-in-stock`}
                  label={`Doar în stoc`}
                  checked={stockFilter === 'in_stock'}
                  onChange={() => handleStockFilterChange('in_stock')}
                />
              </Form>
            </Card.Body>
          </Card>

          {/* Selectarea filtrului */}
          <Card className='mb-3 shadow-sm'>
            <Card.Body>
              <h5 className='text-center mb-3'>Selectează un filtru</h5>
              <Form.Select
                aria-label="Selectează un filtru"
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
              >
                <option value=''>-- Selectează --</option>
                {filterFields
                  .filter(filter => (getFilteredOptionsForSelectedFilter[filter.key] && getFilteredOptionsForSelectedFilter[filter.key].length > 0))
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((filter, index) => {
                    const availableOptionsCount = getFilteredOptionsForSelectedFilter[filter.key] ? getFilteredOptionsForSelectedFilter[filter.key].length : 0;
                    return (
                      <option key={index} value={filter.key}>
                        {filter.label} ({availableOptionsCount})
                      </option>
                    );
                  })}
              </Form.Select>
            </Card.Body>
          </Card>

          {/* Opțiunile filtrului selectat */}
          {selectedFilter && (
            <Card className='mb-3 shadow-sm'>
              <Card.Body>
                <h5 className='text-center mb-3'>
                  Filtrează după {filterFields.find(f => f.key === selectedFilter)?.label}
                </h5>
                <Form>
                  {getFilteredOptionsForSelectedFilter[selectedFilter]
                    .filter(Boolean)
                    .map((option, index) => (
                      <Form.Check
                        key={index}
                        type='checkbox'
                        id={`checkbox-${selectedFilter}-${option}`}
                        label={option}
                        checked={(selectedFilters[selectedFilter] || []).includes(option)}
                        onChange={(e) => handleCheckboxChange(selectedFilter, option, e.target.checked)}
                      />
                    ))}
                </Form>
              </Card.Body>
            </Card>
          )}
        </Col>

        <Col md={9}>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '400px' }}>
              <Spinner animation="border" role="status" variant="primary" />
              <span className="ms-3">Încărcăm datele...</span>
            </div>
          ) : (
            <>
    <Card className='mb-3 shadow-sm'>
  <Card.Body>
    <div className="floating-search">
      <Form.Control
        type="text"
        id="floatingSearch"
        placeholder=" "
        value={searchQuery}
        onChange={handleSearch}
        className="floating-search-input"
      />
      <label htmlFor="floatingSearch">Caută</label>
    </div>
  </Card.Body>
</Card>
              <Table striped bordered hover className='shadow-sm'>
                <thead className='thead-dark'>
                  <tr>
                    {visibleColumns.map((columnName, index) => (
                      <th key={index}>
                        {columnName}
                        {(columnName === 'Pret achizitie RON' || columnName === 'Pret achizitie EUR' || columnName === 'Pret achizitie USD' || columnName === 'Pret de vanzare' || columnName === 'Stoc') && (
                          <Button
                            variant='link'
                            size='sm'
                            onClick={() => handleSort(columnName)}
                            className='p-0 m-0'
                            style={{
                              verticalAlign: 'middle',
                              textDecoration: 'none',
                              fontWeight: sortConfig.key === columnName ? 'bold' : 'normal'
                            }}
                          >
                            {sortConfig.key === columnName && sortConfig.direction === 'ascending' ? '▲' : '▼'}
                          </Button>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item, index) => {
                    const { highlightedDescription, highlightedPN } = highlightSearchTerms(item['Descriere'], item['PN']);

                    return (
                      <tr key={index} onClick={() => handleRowClick(item)} style={{ cursor: 'pointer' }}>
                        {visibleColumns.map((columnName, columnIndex) => (
                          <td key={columnIndex}>
                            {columnName === 'Descriere' ? (
                              <div dangerouslySetInnerHTML={{
                                __html: truncateText(highlightedDescription, 15)
                              }} />
                            ) : columnName === 'PN' ? (
                              <div dangerouslySetInnerHTML={{ __html: highlightedPN }} />
                            ) : columnName.startsWith('Pret achizitie') ? (
                              selectedCurrency === 'RON' ? item['Pret achizitie RON'] :
                                selectedCurrency === 'EUR' ? item['Pret achizitie EUR'] :
                                  selectedCurrency === 'USD' ? item['Pret achizitie USD'] : ''
                            ) : columnName === 'Pret de vanzare' ? (
                              calculateCustomColumnValue(item)
                            ) : columnName === 'Stoc' ? (
                              item[columnName]
                            ) : (
                              item[columnName]
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <div className="d-flex justify-content-center mt-3 mb-3">
                <Button
                  variant="outline-primary"
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                >
                  Anterior
                </Button>
                <span className="mx-3 align-self-center">
                  Pagina {currentPage} din {Math.ceil(filteredData.length / itemsPerPage)}
                </span>
                <Button
                  variant="outline-primary"
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(filteredData.length / itemsPerPage)))}
                  disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
                >
                  Următor
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>

      <Button
        variant="primary"
        onClick={scrollToTop}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000,
          borderRadius: '50%',
          padding: '10px 15px',
        }}
      >
        <FaArrowUp size={20} />
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Detalii Produs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Detaliu</th>
                  <th>Valoare</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(selectedItem).map((key, index) => (
                  <tr key={index}>
                    <td>{key}</td>
                    <td>{selectedItem[key]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Închide
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Page;