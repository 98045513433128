import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login/login';
import Page from './components/Page/page';
import Crud from './components/Crud/crud';
import Romania from './components/RomaniaAll/romania_all';

const ProtectedRoute = ({ children, isLoggedIn }) => {
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedLoginStatus = sessionStorage.getItem('isLoggedIn');
    return storedLoginStatus === 'true';
  });

  const handleLogin = () => {
    setIsLoggedIn(true);
    sessionStorage.setItem('isLoggedIn', 'true');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    sessionStorage.removeItem('isLoggedIn');
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Page onLogout={handleLogout} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/page"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Page onLogout={handleLogout} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/crud"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Crud onLogout={handleLogout} />
            </ProtectedRoute>
          }
        />
        {/* imd */}
        <Route
          path="/romania_all"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Romania onLogout={handleLogout} />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
