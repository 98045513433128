import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Modal, Container, Card } from 'react-bootstrap';
import axios from 'axios';
import './Crud.css'

const Crud = () => {
  const [produse, setProduse] = useState([]);
  const [afiseazaModal, setAfiseazaModal] = useState(false);
  const [modEditare, setModEditare] = useState(false);
  const [produsCurent, setProdusCurent] = useState(null);
  const [moneda, setMoneda] = useState('RON');
  const [exchangeRates, setExchangeRates] = useState(null);
  const [dateFormular, setDateFormular] = useState({
    'Distribuitor': '',
    'Brand': '',
    'PN': '',
    'Titlu': '',
    'Descriere': '',
    'Stoc': '',
    'Pret achizitie': '',
    'Pret achizitie RON': '',
    'Pret achizitie EUR': '',
    'Pret achizitie USD': '',
    'EUR EXW 0%': '',
    'USD EXW 0%': '',
    'EUR EXW 5%': '',
    'USD EXW 5%': '',
    'EUR CIF 0%': '',
    'USD CIF 0%': '',
    'EUR CIF 5%': '',
    'USD CIF 5%': '',
    'Tara': '',
    'Tastatura': '',
    'Tip produs': 'Notebook' // Noua proprietate adăugată
  });

  useEffect(() => {
    const fetchProduse = async () => {
      try {
        const response = await axios.get('https://api.sigmadistribution.ro/api/products');
        setProduse(response.data);
      } catch (error) {
        console.error('Eroare la preluarea produselor:', error);
      }
    };

    const fetchExchangeRates = async () => {
      try {
        const response = await axios.get('https://api.sigmadistribution.ro/api/exchange-rates');
        setExchangeRates(response.data);
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
      }
    };

    fetchProduse();
    fetchExchangeRates();
  }, []);

  useEffect(() => {
    if (!exchangeRates) return;

    const pretachizitie = parseFloat(dateFormular['Pret achizitie']);
    if (!isNaN(pretachizitie) && pretachizitie > 0) {
      const preturi = calculeazaPreturi(pretachizitie, moneda);
      setDateFormular((prevState) => ({
        ...prevState,
        ...preturi,
      }));
    } else {
      setDateFormular((prevState) => ({
        ...prevState,
        'Pret achizitie RON': '',
        'Pret achizitie EUR': '',
        'Pret achizitie USD': '',
        'EUR EXW 0%': '',
        'USD EXW 0%': '',
        'EUR EXW 5%': '',
        'USD EXW 5%': '',
        'EUR CIF 0%': '',
        'USD CIF 0%': '',
        'EUR CIF 5%': '',
        'USD CIF 5%': '',
      }));
    }
  }, [dateFormular['Pret achizitie'], moneda, exchangeRates]);

  const ceilToInteger = (num) => {
    return Math.ceil(num);
  };

  const calculeazaPreturi = (pret, tipMoneda) => {
    if (!exchangeRates) return {};

    const EUR_RON = exchangeRates['EUR']; // RON per EUR
    const USD_RON = exchangeRates['USD']; // RON per USD

    let pretRON, pretEUR, pretUSD;

    if (tipMoneda === 'RON') {
      pretRON = pret;
      pretEUR = pretRON / EUR_RON;
      pretUSD = pretRON / USD_RON;
    } else if (tipMoneda === 'EUR') {
      pretEUR = pret;
      pretRON = pretEUR * EUR_RON;
      pretUSD = pretRON / USD_RON;
    } else if (tipMoneda === 'USD') {
      pretUSD = pret;
      pretRON = pretUSD * USD_RON;
      pretEUR = pretRON / EUR_RON;
    } else {
      // Default to RON if invalid currency
      pretRON = pret;
      pretEUR = pretRON / EUR_RON;
      pretUSD = pretRON / USD_RON;
    }

    // Calcularea prețurilor conform formulelor tale
    const eurEXW0 = ceilToInteger((pretEUR + 4.5 / EUR_RON) * (EUR_RON / (EUR_RON - 0.02)) + 1.5);
    const usdEXW0 = ceilToInteger(((pretEUR + 4.5 / EUR_RON + 1.5) * EUR_RON) / (USD_RON - 0.02));

    const eurEXW5 = ceilToInteger(
      (pretEUR + 4.5 / EUR_RON) * (EUR_RON / (EUR_RON - 0.02)) + 1.5 + 0.05 * pretEUR
    );
    const usdEXW5 = ceilToInteger(
      ((pretEUR + 4.5 / EUR_RON + 1.5 + 0.05 * pretEUR) * EUR_RON) / (USD_RON - 0.02)
    );

    const eurCIF0 = ceilToInteger(
      (pretEUR + 4.5 / EUR_RON) * (EUR_RON / (EUR_RON - 0.02)) +
        1.5 +
        pretEUR * 0.0018 +
        5
    );
    const usdCIF0 = ceilToInteger(
      ((pretEUR + 4.5 / EUR_RON + 1.5 + pretEUR * 0.0018 + 11) * EUR_RON) / (USD_RON - 0.02)
    );

    const eurCIF5 = ceilToInteger(
      (pretEUR + 4.5 / EUR_RON) * (EUR_RON / (EUR_RON - 0.02)) +
        1.5 +
        pretEUR * 0.0018 +
        5 +
        0.05 * pretEUR
    );
    const usdCIF5 = ceilToInteger(
      ((pretEUR + 4.5 / EUR_RON + 1.5 + pretEUR * 0.0018 + 11 + 0.05 * pretEUR) * EUR_RON) /
        (USD_RON - 0.02)
    );

    return {
      'Pret achizitie RON': ceilToInteger(pretRON),
      'Pret achizitie EUR': ceilToInteger(pretEUR),
      'Pret achizitie USD': ceilToInteger(pretUSD),
      'EUR EXW 0%': eurEXW0,
      'USD EXW 0%': usdEXW0,
      'EUR EXW 5%': eurEXW5,
      'USD EXW 5%': usdEXW5,
      'EUR CIF 0%': eurCIF0,
      'USD CIF 0%': usdCIF0,
      'EUR CIF 5%': eurCIF5,
      'USD CIF 5%': usdCIF5,
    };
  };

  const gestioneazaSchimbarea = (e) => {
    const { name, value } = e.target;
    setDateFormular((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const afiseaza = (produs = null) => {
    if (produs) {
      setModEditare(true);
      setProdusCurent(produs);
      setDateFormular({
        'Distribuitor': produs['Distribuitor'] || '',
        'Brand': produs['Brand'] || '',
        'PN': produs['PN'] || '',
        'Titlu': produs['Titlu'] || '',
        'Descriere': produs['Descriere'] || '',
        'Stoc': produs['Stoc'] || '',
        'Pret achizitie': produs['Pret achizitie'] || '',
        'Pret achizitie RON': produs['Pret achizitie RON'] || '',
        'Pret achizitie EUR': produs['Pret achizitie EUR'] || '',
        'Pret achizitie USD': produs['Pret achizitie USD'] || '',
        'EUR EXW 0%': produs['EUR EXW 0%'] || '',
        'USD EXW 0%': produs['USD EXW 0%'] || '',
        'EUR EXW 5%': produs['EUR EXW 5%'] || '',
        'USD EXW 5%': produs['USD EXW 5%'] || '',
        'EUR CIF 0%': produs['EUR CIF 0%'] || '',
        'USD CIF 0%': produs['USD CIF 0%'] || '',
        'EUR CIF 5%': produs['EUR CIF 5%'] || '',
        'USD CIF 5%': produs['USD CIF 5%'] || '',
        'Tara': produs['Tara'] || '',
        'Tastatura': produs['Tastatura'] || '',
        'Tip produs': produs['Tip produs'] || 'Notebook' 
      });
    } else {
      setModEditare(false);
      setProdusCurent(null);
      setDateFormular({
        'Distribuitor': '',
        'Brand': '',
        'PN': '',
        'Titlu': '',
        'Descriere': '',
        'Stoc': '',
        'Pret achizitie': '',
        'Pret achizitie RON': '',
        'Pret achizitie EUR': '',
        'Pret achizitie USD': '',
        'EUR EXW 0%': '',
        'USD EXW 0%': '',
        'EUR EXW 5%': '',
        'USD EXW 5%': '',
        'EUR CIF 0%': '',
        'USD CIF 0%': '',
        'EUR CIF 5%': '',
        'USD CIF 5%': '',
        'Tara': '',
        'Tastatura': '',
        'Tip produs': 'Notebook' 
      });
    }
    setAfiseazaModal(true);
  };

  const inchide = () => {
    setAfiseazaModal(false);
  };

  const salveaza = async () => {
    try {
      const pretachizitie = parseFloat(dateFormular['Pret achizitie']);
      if (isNaN(pretachizitie) || pretachizitie <= 0) {
        alert('Vă rugăm să introduceți un preț de achiziție valid.');
        return;
      }

      const parsePrice = (value) => {
        const num = parseFloat(value);
        return isNaN(num) ? null : num;
      };

      const dateProdus = {
        'Distribuitor': dateFormular['Distribuitor'],
        'Brand': dateFormular['Brand'],
        'PN': dateFormular['PN'],
        'Titlu': dateFormular['Titlu'],
        'Descriere': dateFormular['Descriere'],
        'Stoc': parseInt(dateFormular['Stoc'], 10) || 0,
        'Pret achizitie': pretachizitie,
        'Pret achizitie RON': parsePrice(dateFormular['Pret achizitie RON']),
        'Pret achizitie EUR': parsePrice(dateFormular['Pret achizitie EUR']),
        'Pret achizitie USD': parsePrice(dateFormular['Pret achizitie USD']),
        'EUR EXW 0%': parsePrice(dateFormular['EUR EXW 0%']),
        'USD EXW 0%': parsePrice(dateFormular['USD EXW 0%']),
        'EUR EXW 5%': parsePrice(dateFormular['EUR EXW 5%']),
        'USD EXW 5%': parsePrice(dateFormular['USD EXW 5%']),
        'EUR CIF 0%': parsePrice(dateFormular['EUR CIF 0%']),
        'USD CIF 0%': parsePrice(dateFormular['USD CIF 0%']),
        'EUR CIF 5%': parsePrice(dateFormular['EUR CIF 5%']),
        'USD CIF 5%': parsePrice(dateFormular['USD CIF 5%']),
        'Tara': dateFormular['Tara'],
        'Tastatura': dateFormular['Tastatura'],
        'Tip produs': dateFormular['Tip produs'] // Include 'Tip produs' în datele trimise
      };

      if (modEditare && produsCurent) {
        await axios.put(
          `https://api.sigmadistribution.ro/api/products/${produsCurent.id}`,
          dateProdus
        );
        const produseActualizate = produse.map((produs) =>
          produs.id === produsCurent.id ? { ...dateProdus, id: produsCurent.id } : produs
        );
        setProduse(produseActualizate);
        alert('Produsul a fost actualizat cu succes.');
      } else {
        const raspuns = await axios.post(
          'https://api.sigmadistribution.ro/api/products',
          dateProdus
        );
        const produseNoi = [...produse, raspuns.data];
        setProduse(produseNoi);
        alert('Produsul a fost adăugat cu succes.');
      }
      inchide();
    } catch (eroare) {
      console.error('Eroare la salvarea produsului:', eroare);
      alert(
        `Produsul nu a putut fi salvat: ${eroare.response?.data?.message || eroare.message}`
      );
    }
  };

  const sterge = async (id) => {
    if (!window.confirm('Ești sigur că vrei să ștergi acest produs?')) {
      return;
    }

    try {
      await axios.delete(`https://api.sigmadistribution.ro/api/products/${id}`);
      const produseActualizate = produse.filter((produs) => produs.id !== id);
      setProduse(produseActualizate);
      alert('Produsul a fost șters cu succes.');
    } catch (eroare) {
      console.error('Eroare la ștergerea produsului:', eroare);
      alert('Produsul nu a putut fi șters. Verifică consola pentru detalii.');
    }
  };

  if (!exchangeRates) {
    return <div>Se încarcă ratele de schimb...</div>;
  }

  return (
    <Container className="mt-4">
      <Card>
        <Card.Body>
          <div className="mb-3">
            <button className='add-product-button' onClick={() => afiseaza()}>
              Adaugă Produs
            </button>
          </div>
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Distribuitor</th>
                  <th>Brand</th>
                  <th>PN</th>
                  <th>Titlu</th>
                  <th>Descriere</th>
                  <th>Stoc</th>
                  <th>Pret Achizitie (RON)</th>
                  <th>Pret Achizitie (EUR)</th>
                  <th>Pret Achizitie (USD)</th>
                  <th>EUR EXW 0%</th>
                  <th>USD EXW 0%</th>
                  <th>EUR EXW 5%</th>
                  <th>USD EXW 5%</th>
                  <th>EUR CIF 0%</th>
                  <th>USD CIF 0%</th>
                  <th>EUR CIF 5%</th>
                  <th>USD CIF 5%</th>
                  <th>Tara</th>
                  <th>Tastatura</th>
                  <th>Tip produs</th> {/* Noua coloană */}
                  <th>Acțiuni</th>
                </tr>
              </thead>
              <tbody>
                {produse.length === 0 ? (
                  <tr>
                    <td colSpan="22" className="text-center">
                      Nu există produse.
                    </td>
                  </tr>
                ) : (
                  produse.map((produs) => (
                    <tr key={produs.id}>
                      <td>{produs.id}</td>
                      <td>{produs.Distribuitor}</td>
                      <td>{produs.Brand}</td>
                      <td>{produs.PN}</td>
                      <td>{produs.Titlu}</td>
                      <td>{produs.Descriere}</td>
                      <td>{produs.Stoc}</td>
                      <td>{produs['Pret achizitie RON']}</td>
                      <td>{produs['Pret achizitie EUR']}</td>
                      <td>{produs['Pret achizitie USD']}</td>
                      <td>{produs['EUR EXW 0%']}</td>
                      <td>{produs['USD EXW 0%']}</td>
                      <td>{produs['EUR EXW 5%']}</td>
                      <td>{produs['USD EXW 5%']}</td>
                      <td>{produs['EUR CIF 0%']}</td>
                      <td>{produs['USD CIF 0%']}</td>
                      <td>{produs['EUR CIF 5%']}</td>
                      <td>{produs['USD CIF 5%']}</td>
                      <td>{produs.Tara}</td>
                      <td>{produs.Tastatura}</td>
                      <td>{produs['Tip produs']}</td> {/* Afișează 'Tip produs' */}
                      <td>
                      <Button
  variant="warning"
  size="sm"
  className="me-2 edit-button" // Add the edit-button class
  onClick={() => afiseaza(produs)}
>
  Edit
</Button>
<Button
  variant="danger"
  size="sm"
  className="delete-button" // Keep the delete-button class
  onClick={() => sterge(produs.id)}
>
  Delete
</Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>

      <Modal show={afiseazaModal} onHide={inchide}>
        <Modal.Header closeButton>
          <Modal.Title>{modEditare ? 'Editează Produs' : 'Adaugă Produs'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Dropdown pentru 'Tip produs' */}
            <Form.Group className="mb-3">
              <Form.Label>Tip produs</Form.Label>
              <Form.Control
                as="select"
                name="Tip produs"
                value={dateFormular['Tip produs']}
                onChange={gestioneazaSchimbarea}
                required
              >
                <option value="">Selectează tipul de produs</option>
                <option value="AIO">AIO</option>
                <option value="Notebook">Notebook</option>
                <option value="Desktop">Desktop</option>
                <option value="Monitoare">Monitoare</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Selectează Moneda</Form.Label>
              <Form.Control
                as="select"
                value={moneda}
                onChange={(e) => {
                  const nouaMoneda = e.target.value;
                  setMoneda(nouaMoneda);
                  const pretachizitie = parseFloat(dateFormular['Pret achizitie']);
                  if (!isNaN(pretachizitie) && pretachizitie > 0) {
                    const preturi = calculeazaPreturi(pretachizitie, nouaMoneda);
                    setDateFormular((prevState) => ({
                      ...prevState,
                      ...preturi,
                    }));
                  }
                }}
              >
                <option value="RON">RON</option>
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Preț Achiziție ({moneda})</Form.Label>
              <Form.Control
                type="number"
                name="Pret achizitie"
                value={dateFormular['Pret achizitie']}
                onChange={gestioneazaSchimbarea}
                min="0"
                step="0.01"
                required
              />
            </Form.Group>
            {Object.keys(dateFormular).map(
              (key) =>
                ![
                  'Pret achizitie',
                  'Pret achizitie RON',
                  'Pret achizitie EUR',
                  'Pret achizitie USD',
                  'EUR EXW 0%',
                  'USD EXW 0%',
                  'EUR EXW 5%',
                  'USD EXW 5%',
                  'EUR CIF 0%',
                  'USD CIF 0%',
                  'EUR CIF 5%',
                  'USD CIF 5%',
                  'Tip produs' // Excludem 'Tip produs' deoarece l-am adăugat manual mai sus
                ].includes(key) && (
                  <Form.Group key={key} className="mb-3">
                    <Form.Label>{key}</Form.Label>
                    <Form.Control
                      type="text"
                      name={key}
                      value={dateFormular[key]}
                      onChange={gestioneazaSchimbarea}
                    />
                  </Form.Group>
                )
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={inchide}>
            Anulează
          </Button>
          <Button className="save-button" onClick={salveaza}>
  {modEditare ? 'Actualizează' : 'Salvează'}
</Button>

        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Crud;
