import React, { useEffect, useState } from 'react';

const Romania = () => {
  const [originalProducts, setOriginalProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Stări pentru pagină, căutări și sortări
  const [currentPage, setCurrentPage] = useState(1);
  const [searchProducator, setSearchProducator] = useState('');
  const [searchNume, setSearchNume] = useState('');
  const [searchPN, setSearchPN] = useState('');
  const [filterStock, setFilterStock] = useState(false);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  const itemsPerPage = 10;

  
  useEffect(() => {
    fetch('https://api.sigmadistribution.ro/romania')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Marcăm indexul original
        const indexedData = data.map((prod, idx) => ({
          ...prod,
          _originalIndex: idx,
        }));

        setOriginalProducts(indexedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Eroare la interogarea API-ului:', error.message);
        setError(`Eroare API: ${error.message}`);
        setLoading(false);
      });
  }, []);

  // 2) Reset complet
  const resetFilters = () => {
    setSearchProducator('');
    setSearchNume('');
    setSearchPN('');
    setFilterStock(false);
    setSortField('');
    setSortOrder('');
    setCurrentPage(1);
  };

 
  const handlePNChange = (e) => {
    const rawValue = e.target.value;
    setSearchPN(rawValue);
    setCurrentPage(1);
  };


  const filteredProducts = originalProducts.filter((product) => {
    const matchesProducator = product.Producator
      .toLowerCase()
      .includes(searchProducator.trim().toLowerCase());
    const matchesNume = product.Nume
      .toLowerCase()
      .includes(searchNume.trim().toLowerCase());
    const matchesPN = product.PN
      .toLowerCase()
      .includes(searchPN.trim().toLowerCase());
    const hasStock = !filterStock || product.Stoc > 0;
    return matchesProducator && matchesNume && matchesPN && hasStock;
  });

  if (sortField && sortOrder) {
  
    filteredProducts.sort((a, b) => {
      const aValue = sortField === 'Stoc' ? a.Stoc : a.Pret;
      const bValue = sortField === 'Stoc' ? b.Stoc : b.Pret;

      if (sortOrder === 'asc') return aValue - bValue;
      if (sortOrder === 'desc') return bValue - aValue;
      return 0;
    });
  } else {
   
    filteredProducts.sort((a, b) => a._originalIndex - b._originalIndex);
  }

  // paginare
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
    }
  };

  
  const highlightText = (text, searchTerm) => {
    if (!searchTerm.trim()) return text;
    const regex = new RegExp(`(${searchTerm.trim()})`, 'gi');
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span
          key={index}
          style={{
            backgroundColor: 'yellow',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // 6) Export CSV
  const exportToCSV = () => {
    const headers = [
      'Producator',
      'Cat1',
      'Cat2',
      'PN',
      'Nume',
      'Descriere',
      'Stoc',
      'Pret',
      'Distribuitor',
    ];

    const rows = filteredProducts.map((product) => [
      product.Producator,
      product.Cat1,
      product.Cat2,
      product.PN,
      product.Nume,
      product.Descriere,
      product.Stoc,
      product.Pret,
      product.Distribuitor,
    ]);

    const csvContent = [headers, ...rows]
      .map((row) => row.map((item) => `"${item}"`).join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

 
  return (
    <div>
      <h1 style={{ textAlign: 'center', margin: '20px 0' }}>Produsele din Romania</h1>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <div
            style={{
              width: '50px',
              height: '50px',
              border: '5px solid #f3f3f3',
              borderTop: '5px solid orange',
              borderRadius: '50%',
              animation: 'spin 1s linear infinite',
            }}
          />
          <style>{`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}</style>
        </div>
      ) : error ? (
        <div style={{ color: 'red' }}>
          <p>A apărut o eroare: {error}</p>
        </div>
      ) : (
        <div style={{ margin: '0 20px' }}>
         
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <button
              onClick={() => (window.location.href = 'http://localhost:3000/page')}
              style={{
                padding: '10px 20px',
                margin: '5px',
                borderRadius: '5px',
                backgroundColor: '#ccc',
                border: '1px solid #ddd',
                cursor: 'pointer',
              }}
            >
              Înapoi la Main
            </button>
          </div>

          
          <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
          <div style={{ textAlign: 'center' }}>
              <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>
                Caută după PN
              </label>
              <input
                type="text"
                placeholder="PN..."
                value={searchPN}
                onChange={handlePNChange}
                style={{
                  padding: '10px',
                  fontSize: '16px',
                  width: '200px',
                  borderRadius: '5px',
                  border: '1px solid #ddd',
                }}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>
                Caută după Producator
              </label>
              <input
                type="text"
                placeholder="Producator..."
                value={searchProducator}
                onChange={(e) => {
                  setSearchProducator(e.target.value);
                  setCurrentPage(1);
                }}
                style={{
                  padding: '10px',
                  fontSize: '16px',
                  width: '200px',
                  borderRadius: '5px',
                  border: '1px solid #ddd',
                }}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>
                Caută după Nume
              </label>
              <input
                type="text"
                placeholder="Nume..."
                value={searchNume}
                onChange={(e) => {
                  setSearchNume(e.target.value);
                  setCurrentPage(1);
                }}
                style={{
                  padding: '10px',
                  fontSize: '16px',
                  width: '200px',
                  borderRadius: '5px',
                  border: '1px solid #ddd',
                }}
              />
            </div>
          </div>

          
          <div style={{ marginBottom: '20px', textAlign: 'center' }}>
            <button
              onClick={() => {
                setFilterStock(!filterStock);
                setCurrentPage(1);
              }}
              style={{
                padding: '10px 20px',
                margin: '5px',
                borderRadius: '5px',
                backgroundColor: filterStock ? 'orange' : '#f4f4f4',
                border: '1px solid #ddd',
                cursor: 'pointer',
              }}
            >
              {filterStock ? 'Toate Produsele' : 'Doar în Stoc'}
            </button>
            <button
              onClick={() => {
                setSortField('Stoc');
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                setCurrentPage(1);
              }}
              style={{
                padding: '10px 20px',
                margin: '5px',
                borderRadius: '5px',
                backgroundColor: '#f4f4f4',
                border: '1px solid #ddd',
                cursor: 'pointer',
              }}
            >
              {sortField === 'Stoc'
                ? `Sortează Stoc (${sortOrder === 'asc' ? 'Crescător' : 'Descrescător'})`
                : 'Sortează Stoc'}
            </button>
            <button
              onClick={() => {
                setSortField('Pret');
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                setCurrentPage(1);
              }}
              style={{
                padding: '10px 20px',
                margin: '5px',
                borderRadius: '5px',
                backgroundColor: '#f4f4f4',
                border: '1px solid #ddd',
                cursor: 'pointer',
              }}
            >
              {sortField === 'Pret'
                ? `Sortează Preț (${sortOrder === 'asc' ? 'Crescător' : 'Descrescător'})`
                : 'Sortează Preț'}
            </button>
            <button
              onClick={resetFilters}
              style={{
                padding: '10px 20px',
                margin: '5px',
                borderRadius: '5px',
                backgroundColor: '#ff6666',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Resetează Filtrele
            </button>
            <button
              onClick={exportToCSV}
              style={{
                padding: '10px 20px',
                margin: '5px',
                borderRadius: '5px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Exportă în CSV
            </button>
          </div>

          
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
            <thead>
              <tr style={{ backgroundColor: '#f4f4f4', textAlign: 'left' }}>
                <th style={{ border: '1px solid #ddd', padding: '8px', color: 'black' }}>
                  Producator
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px', color: 'black' }}>
                  Cat1
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px', color: 'black' }}>
                  Cat2
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px', color: 'black' }}>
                  PN
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px', color: 'black' }}>
                  Nume
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px', color: 'black' }}>
                  Descriere
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px', color: 'black' }}>
                  Stoc
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px', color: 'black' }}>
                  Pret
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px', color: 'black' }}>
                  Distribuitor
                </th>
              </tr>
            </thead>
            <tbody>
              {currentProducts.map((product) => (
                <tr key={product._originalIndex} style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {highlightText(product.Producator, searchProducator)}
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{product.Cat1}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{product.Cat2}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {highlightText(product.PN, searchPN)}
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {highlightText(product.Nume, searchNume)}
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {product.Descriere}
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{product.Stoc}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{product.Pret}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {product.Distribuitor}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <span>Pagina: </span>
            <select
              value={currentPage}
              onChange={(e) => handlePageChange(Number(e.target.value))}
              style={{
                padding: '10px',
                fontSize: '16px',
                margin: '10px 0',
                borderRadius: '5px',
                border: '1px solid #ddd',
              }}
            >
              {Array.from({ length: totalPages }, (_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default Romania;
