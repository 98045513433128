import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import './Login.css'; // Import the scoped CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [loginError, setLoginError] = useState(false); 
  const [showPassword, setShowPassword] = useState(false); 

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('https://api.sigmadistribution.ro/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        onLogin(true); 
        setIsLoggedIn(true); 
        setLoginError(false); 
      } else {
        onLogin(false); 
        setLoginError(true); 
      }
    } catch (error) {
      console.error('Error:', error);
      onLogin(false); 
      setLoginError(true); 
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/page" />;
  }

  return (
    <div className="login-page-container">
      <div className="login-card-wrapper">
        <div className="login-page-card">
          <h2 className="login-page-title">Login</h2>
          {loginError && (
            <div className="alert" role="alert">
              Invalid username or password. Please try again.
            </div>
          )}
          <form onSubmit={handleSubmit}>
            {/* Floating label for Username */}
            <div className="floating-label-group">
              <input
                type="text"
                className="login-page-form-control"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder=" " /* Placeholder must be a space for floating label effect */
                required
              />
              <label className="floating-label" htmlFor="username">Username</label>
            </div>

            {/* Floating label for Password */}
            <div className="floating-label-group">
              <input
                type={showPassword ? "text" : "password"}
                className="login-page-form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder=" " /* Placeholder must be a space for floating label effect */
                required
              />
              <label className="floating-label" htmlFor="password">Password</label>
              <button
                className="password-toggle-btn"
                type="button"
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            
            <button type="submit" className="login-page-btn mt-3">
              Login
            </button>
          </form>
        </div>

        {/* Copyright section */}
        <p className="copyright-section">
          © 2024 SigmaDistribution.ro. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Login;
